import { render, staticRenderFns } from "./EmailsTable.vue?vue&type=template&id=056505e9&scoped=true"
import script from "./EmailsTable.vue?vue&type=script&lang=js"
export * from "./EmailsTable.vue?vue&type=script&lang=js"
import style0 from "./EmailsTable.vue?vue&type=style&index=0&id=056505e9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "056505e9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VDataTable,VIcon,VRow})
